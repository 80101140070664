<template>
	<div>
		<div class="main pl-3">
			<p class="pt-3">MOBILNY CZYTNIK CZASU PRACY W CHMURZE</p>            
			<div>
				<div>
					<a 
					href="https://clockint.eu/kontakt/" target="_blank">
						Kontakt
					</a>
				</div>
				<br>

				<div>
					Wersja: {{ }}
					<p>API: {{ current_env}}</p>
				</div>
				<div>
					(C) Shared Masters 2021
				</div>

				<div>
					<a href="https://clockint.eu/polityka-prywatnosci/" target="_blank">
					Polityka prywatności
					</a>
				</div>

				<div>
					<a
					href="https://clockint.eu/" target="_blank">
						Dowiedz się więcej
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import appConfig from '@/app_config'


export default {
	name: 'About',

	data: () => ({
		title: "About ClockInt",
		current_env: appConfig.getApiUrl(localStorage.getItem("tenantSlug"))
	}),

	methods: {
		setTitle() {
			this.$emit('set-title', this.title)
		},
	},
	mounted() {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.$emit("set-state", "default");
		this.setTitle(this.title)
	},
};
</script>

<style>

</style>
