// export default {
//     apiUrl: process.env.VUE_APP_API_URL,
// };

//do wywalenia
export default {
    apiUrl: process.env.VUE_APP_API_URL,
    getApiUrl(tenant_slug=null){
        if(!tenant_slug){
            return this.apiUrl.replace("{TENANT_SLUG}", "");
        }
        else{
            return this.apiUrl.replace("{TENANT_SLUG}", tenant_slug + ".");
        }
    }
};